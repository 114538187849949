<template>
    <div style="width: 100%;padding:10px 30px">
        <div style="width: 100%;background: white;padding: 10px 20px;margin: 10px auto;">

            <div style="height: 30px;">
                <img src="@/assets/icon/back.png" height="100%" style="float: left; cursor: pointer;" @click="handleClose"/>
                <p style="color:#f60;text-align:left;font-size: 24px;padding: 0px;float: left;margin-left: 15px;">
                    <span>{{title}} </span>
                    <span style="color: royalblue;font-size: 16px;font-weight: bold;">(车型: {{baseFormInline.modelno}}</span>
                    <span style="color: royalblue;font-size: 16px;font-weight: bold;">)</span>
                </p>                
            </div>
            <Form  ref="bomModelForm" :model="baseFormInline"   label-position="right" :label-width="100" :disabled="modelnodisable" :rules="baseruleInline" style="margin-top: 15px;width: 80%;clear: both;">
                <FormItem  label="车辆型号:" prop="modelno">
                    <i-input type="text" v-model="baseFormInline.modelno" :maxlength=30 placeholder="车辆型号">
                    </i-input>
                </FormItem>
                <FormItem  label="中文型号:" prop="cnmodelno">
                    <i-input type="text" v-model="baseFormInline.cnmodelno" :maxlength=30 placeholder="中文型号">
                    </i-input>
                </FormItem>
                <Button type="success" size="small" ghost @click="oncheckmodel" >保存车型信息</Button>   
            </Form>
            <div style="margin:20px 10px;width:100%;text-align:left">BOM物料列表：</div>
            <Table :row-class-name="rowClassName" :columns="tblcolumns" :data="tbldata" border size="small"  height="400">
                <template slot-scope="{ row }" slot="materialname">
                    <strong>{{ row.materialname }}</strong>
                </template>
                <template slot-scope="{ row, index }" slot="action" >
                    <Button type="info" size="small" ghost @click="modify(index)" style="float:left">修改</Button>
                    <Poptip
                    confirm
                    title="确定要删除该项物料吗?"
                    width="250px"
                    @on-ok="removeproduct(index)"
                    placement="left"
                    style="float:right"
                    >
                    <Button type="error" size="small" ghost  >删除</Button>   
                </Poptip>
                </template>                            
            </Table>  
            <Card style="margin-top: 20px;">
                <p slot="title" style="text-align: left;">
                    <Icon type="md-add-circle" color="blue" v-if="!isitemmodify"/>
                    <Icon type="ios-create-outline" color="blue" v-if="isitemmodify"/>
                    {{isitemmodify ? '修改BOM物料':'新增BOM物料'}}
                </p>
                <Form ref="BomEditForm" :model="detailFormInline"   label-position="right" :rules="ruleInline" >
                    <Row :gutter="10" index="" style="width: 100%;">
                            <i-col span="6">
                                <FormItem  label="选择物料:" prop="material">
                                    <Cascader v-model="detailFormInline.material"  :data="materialTypelist" @on-change="onMaterialChange"></Cascader>
                                </FormItem>
                            </i-col>
                            <i-col span="4" style="padding-left:5px">
                                <FormItem  label="数量:" prop="amount">
                                    <i-input type="number" v-model="detailFormInline.amount" :maxlength=10 placeholder="数量">
                                        <span slot="append">{{detailFormInline.unit}}</span>
                                    </i-input>
                                </FormItem>
                            </i-col>  
                            <i-col span="4" style="padding-left:5px">
                                <FormItem  label="参考价格:" prop="price">
                                    <i-input type="text" v-model="detailFormInline.price" :maxlength=10 placeholder="参考价格">
                                        <span slot="append">元</span>
                                    </i-input>
                                </FormItem>
                            </i-col>           
                            <i-col span="4">
                                <FormItem  label="供应商:" >
                                    <AutoComplete
                                    v-model="detailFormInline.suppliername"
                                    disabled
                                    :data="suppliersearchlist"
                                    @on-search="searchSupplier"
                                    placeholder="供应商"
                                    ></AutoComplete>
                                </FormItem>
                            </i-col>                                       
                            <i-col span="6" style="padding-left:15px;">
                                <Button type="success" style="font-size:10px;margin-top:33px" icon="md-add" @click="addproduct('BomEditForm')" v-if="!isitemmodify">添加到BOM</Button>
                                <Button type="info" style="font-size:10px;margin-top:33px"  @click="addproduct('BomEditForm')" v-if="isitemmodify">保存修改</Button>
                                <Button type="error" ghost style="font-size:10px;margin-top:33px;margin-left: 8px;"  @click="giveup" v-if="isitemmodify">放弃修改</Button>
                            </i-col>             
                    </Row>                          
                </Form>
            </Card>
            
        </div>
        
        <div slot="footer" style="margin: 25px; display: inline-flex;align-items: center;">
            <Button type="error" size="large"   @click="handleClose" style="margin: 0px 20px;width: 150px;">返回</Button>
        </div>
    </div>
</template>
<script>
    var compare = function(obj1,obj2){
        var val1 = obj1.materialtypeid;
        var val2 = obj2.materialtypeid;
        if(val1 < val2){
        return 1;
        }else if(val1 > val2){
        return -1;
        }else{
        return 0;
        }
    }
    export default {
        name:'BomEdit',
        props:['bomdata','action'],
        data () {
            const validcheck = (rule, value, callback) => {
                if (value == null || value.length<2) {
                    return callback(new Error('请选择物料'))
                } else {
                    return callback()
                }
            }
            const validamount = (rule, value, callback) => {
                if (value == null || value<=0) {
                    return callback(new Error('请设置正确的数量'))
                } else {
                    return callback()
                }
            }
            return {
                companyid:this._self.$root.$store.state.LogonInfo.companyid,
                title:'',
                isitemmodify:false,
                itemmodifyindex:null,
                modelnodisable:false,
                baseFormInline:{
                    cnmodelno:null,
                    modelno:null,
                },
                detailFormInline:{                 
                    materialtypeindex:null,
                    materialinfoindex:0,
                    material:[],
                    price:null,
                    unit:null,
                    amount:0,
                    suppliername:null
                },
                baseruleInline:{
                    modelno: [
                        {required: true,message:'请填写车辆型号', trigger: 'blur'}
                    ],
                },
                ruleInline: {
                    material: [
                        { validator:validcheck, required: true, trigger: 'blur'}
                    ],
                    amount: [
                        { validator:validamount,required: true, trigger: 'blur'}
                    ],
                    price:[
                        {required: true, message: '请填写正确的金额',pattern: /^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/}
                    ]
                },
                tblcolumns: [
                    {
                        type: 'index',
                        align: 'center',
                        width:50
                    },
                    {
                        title: '物料分类',
                        key: 'materialtype',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '物料名',
                        slot: 'materialname',
                        width:100,
                        resizable:true
                    },
                    {
                        title: '规格',
                        key: 'spec'
                    },
                    {
                        title: '数量',
                        key: 'amount',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '单位',
                        key: 'unit',   
                        width:80,
                        resizable:true         
                    },
                    {
                        title: '价格(单位:元)',
                        key: 'price',   
                        width:100,
                        resizable:true         
                    },
                    {
                        title: '供应商',
                        key: 'suppliername',   
                        width:160,
                        resizable:true         
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width:150
                    }
                ],
                tbldata:[],
                dateoption:{
                    disabledDate (date) {
                        return date && date.valueOf() < Date.now()-86400000;
                    }
                },
                //全部供应商+全部物料类型+全部物料信息
                masterdata:{
                    materialTypes:[],
                    materialInfos:[],
                    supplierInfos:[]
                },
                //供应商匹配列表
                suppliersearchlist:[],
                materialTypelist:[],
                materialInfolist:[]
            }
        },
        mounted(){
            console.log('bomedit mounted=============')
            if(this.action =='add'){
                this.title ='新建车型BOM'
                this.getMaterialMasterData()
                this.modelnodisable = false
            }
            else{
                this.title ='修改车型BOM'
                this.refreshbom(this.bomdata.companyid,this.bomdata.modelno)

                this.baseFormInline.modelno = this.bomdata.modelno
                this.baseFormInline.cnmodelno = this.bomdata.cnmodelno
                this.modelnodisable = true
            }
            
            eventBus.$on('materialinfoupdate',(data) => {
                console.log(data)
                this.getMaterialMasterData()
            })
        },
        beforeDestroy(){
            eventBus.$off("materialinfoupdate");
        },
        methods: {
            handleSubmit (postdata) {
                var url = "/api/bom/addnewitem"
                console.log(postdata)
                if(this.isitemmodify){
                    url = "/api/bom/updateitem"
                    postdata.id = this.tbldata[this.itemmodifyindex].id
                }
                this.$axios({
                    method:'post',
                    url:url,
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success(res.data.msg)

                        if(!this.isitemmodify){
                            //新增
                            this.tbldata.push(res.data.data)
                            this.tbldata = this.tbldata.sort(compare)
                        }
                        else{
                            //修改
                            this.$set(this.tbldata, this.itemmodifyindex, res.data.data)
                        }
                        this.isitemmodify = false
                        this.detailFormInline.material =[]
                        this.detailFormInline.price = null
                        this.detailFormInline.amount =0
                        this.detailFormInline.suppliername =null
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                    
                }.bind(this))
            },
            refreshbom(companyid,modelno){
                this.$axios({
                        method:'post',
                        url:'/api/bom/query',
                        data:{companyid,modelno},
                        headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                        transformRequest: function(obj) {
                            var str = [];
                            for(var p in obj){
                                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                            }
                            return str.join("&");
                        }
                    }).then(function(res){
                        if(res.data.resultCode ==0){
                            console.log(res.data)
                            //修改
                            if(this.modelnodisable){
                                if(res.data.data == null){
                                this.$Message.error('BOM数据获取失败')
                                }
                                else{
                                    this.tbldata = res.data.data
                                }
                            }
                            else{
                                //新增检查
                                if(res.data.data == null || res.data.data.length ==0){
                                    this.modelnodisable = true
                                }
                                else{
                                    this.$Message.error({content:'该车型BOM已存在！',duration:3})
                                }
                                return
                            }


                            this.getMaterialMasterData()
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))                
            },
            getMaterialMasterData(){
                this.$axios.post('/api/material/masterdata/query',{companyid: this._self.$root.$store.state.LogonInfo.companyid})
                        .then(function(res){
                        if(res.data.resultCode ==0){
                            //this.$Message.success(res.data.msg)
                            console.log(res.data)
                            if(res.data.data == null){
                                this.$Message.error('物料主数据获取失败')
                            }
                            else{
                                this.masterdata = this.processmaterialshowlist(res.data.data)
                            }
                        }
                        else{
                            this.$Message.error(res.data.msg)
                        }
                        
                }.bind(this))
            },
            processmaterialshowlist(mdata){
                for(var i=0;i<mdata.materialTypes.length;i++){
                    mdata.materialTypes[i].value = i
                    mdata.materialTypes[i].label = mdata.materialTypes[i].materialtype
                    mdata.materialTypes[i].children = []
                    mdata.materialTypes[i].loading = false

                    for(var j=0;j<mdata.materialInfos.length;j++){
                        if(mdata.materialInfos[j].materialtypeId == mdata.materialTypes[i].id){
                            mdata.materialTypes[i].children.push({
                                value: j,
                                label: mdata.materialInfos[j].materialname+'(规格:'+mdata.materialInfos[j].spec+') '+ mdata.materialInfos[j].supplier
                            })
                        }
                    }
                }
                this.materialTypelist = JSON.parse(JSON.stringify(mdata.materialTypes))
                console.log(mdata)
                return  mdata
            },
            searchSupplier(value){
                if(value ==null){
                    this.suppliersearchlist =[]
                    return
                }
                var supplierlist =this.masterdata.supplierInfos
                this.suppliersearchlist=[]
                for(var i=0;supplierlist !=null && i<supplierlist.length;i++){
                    if(supplierlist[i].suppliername.indexOf(value) !=-1){
                        this.suppliersearchlist.push(supplierlist[i].suppliername)
                    }
                }
                console.log(this.suppliersearchlist)
            },
            addproduct(formname){
                console.log(this.detailFormInline)
                if(this.baseFormInline.modelno == null || this.baseFormInline.modelno == ''){
                    this.$Message.error({content:'请先填写车型！',duration:3})
                    return
                }
                this.$refs[formname].validate((valid)=>{
                    if(valid){
                        this.detailFormInline.materialinfoindex = this.detailFormInline.material[1]
                        this.detailFormInline.materialtypeindex = this.detailFormInline.material[0]
                        this.detailFormInline.companyid = this._self.$root.$store.state.LogonInfo.companyid
                        console.log(this.masterdata)
                        this.detailFormInline.materialname = this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].materialname
                        this.detailFormInline.materialspec = this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].spec
                        this.detailFormInline.materialtype = this.masterdata.materialTypes[this.detailFormInline.materialtypeindex].materialtype
                        
                        var postdata ={
                            companyid: this._self.$root.$store.state.LogonInfo.companyid,
                            modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                            modelno:this.baseFormInline.modelno,
                            cnmodelno:this.baseFormInline.cnmodelno,
                            materialid: this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].id,
                            unit:this.masterdata.materialInfos[this.detailFormInline.materialinfoindex].unit,
                            materialtypeid: this.masterdata.materialTypes[this.detailFormInline.materialtypeindex].id,  
                            price: this.detailFormInline.price,            
                            amount: this.detailFormInline.amount,
                            suppliername: this.detailFormInline.suppliername                     
                        }

                        for(let item in postdata){
                            if(postdata[item] == null){
                                delete postdata[item]
                            }
                        }
                        this.handleSubmit(postdata)
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
                
            },
            onMaterialChange(e){
                console.log(e)
                if(e.length>1){
                    this.detailFormInline.unit = this.masterdata.materialInfos[e[1]].unit
                    this.detailFormInline.suppliername = this.masterdata.materialInfos[e[1]].supplier
                }
                
            },
            giveup(){
                this.isitemmodify = false
                this.detailFormInline.material =[]
                this.detailFormInline.price = null
                this.detailFormInline.amount =0
                this.detailFormInline.suppliername =null
            },
            removeproduct(index) {
                this.$axios({
                    method:'post',
                    url:"/api/bom/deleteitem",
                    data:{id:this.tbldata[index].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.tbldata.splice(index,1)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this)); 
                
            },
            modify (index){
                this.detailFormInline.material = [this.getmaterialtypeindex(this.tbldata[index].materialtypeid),this.getmaterialinfoindex(this.tbldata[index].materialid)]
                this.detailFormInline.amount = this.tbldata[index].amount
                this.detailFormInline.price = this.tbldata[index].price
                this.detailFormInline.suppliername = this.tbldata[index].suppliername
                this.isitemmodify = true
                this.itemmodifyindex = index
            },
            handleClose(){
                this.$emit('closeedit','')
            },
            getmaterialtypeindex(id){
                for(var i=0;i<this.masterdata.materialTypes.length;i++){
                    if(this.masterdata.materialTypes[i].id == id){
                        return i
                    }
                }
            },
            getmaterialinfoindex(id){
                for(var i=0;i<this.masterdata.materialInfos.length;i++){
                    if(this.masterdata.materialInfos[i].id == id){
                        return i
                    }
                }
            },
            rowClassName (row, index) {
                if (index == this.itemmodifyindex && this.isitemmodify) {
                    return 'demo-table-warning-row';
                }
                return '';
            },
            oncheckmodel(){
                if(this.baseFormInline.modelno == null || this.baseFormInline.modelno == ''){
                    this.$Message.error({content:'请输入车辆型号',duration:3})
                    return
                }
                this.refreshbom(this.companyid,this.baseFormInline.modelno)
            }
        },
        components:{

        }
    }
</script>
<style>
    .ivu-table .demo-table-warning-row td{
        background-color: #b6f7b3;
        color: rgb(54, 12, 240);
    }
</style>