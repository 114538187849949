<style>
    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content {
        height: 120px;
        margin-top: -16px;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-content > .ivu-tabs-tabpane {
        background: #fff;
        padding: 16px;
    }

    .demo-tabs-style1 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab {
        border-color: transparent;
    }

    .demo-tabs-style1 > .ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active {
        border-color: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab{
        border-radius: 0;
        background: #fff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active{
        border-top: 1px solid #3399ff;
    }
    .demo-tabs-style2 > .ivu-tabs.ivu-tabs-card > .ivu-tabs-bar .ivu-tabs-tab-active:before{
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #3399ff;
        position: absolute;
        top: 0;
        left: 0;
    }
</style>
<template>
    <div style="background: #ffffff;height:100%;margin:auto;margin-top:0px;padding:0px;">
        <loginfobar v-if="!isV2" style="width:100%;margin:auto;margin-top:0px;padding:0px 40px 0px"/>
        <div style="margin:auto;width:100%;background:#cbd4f4;padding-bottom:15px">
            <h1 v-if="!isV2" style="padding-top:8px">技术部门业务管理系统</h1>
            <Tabs type="card" style="background: #e3e8ee;margin:auto;padding:10px;margin-top:1%;width:100%;">
                <TabPane v-hasPermission="'certemplate:view'" label="合格证管理">
                    <certificatetemplate />
                </TabPane>
                <TabPane v-if="checkpermission('model:view')" label="车型管理">
                    <mastermodel departid="103"/>
                </TabPane>
                <TabPane v-if="checkpermission('material:view')" label="物料分类管理">
                    <materialtype />
                </TabPane>
                <TabPane v-if="checkpermission('material:view')" label="物料主数据管理">
                    <materialinfo />
                </TabPane>
                <TabPane v-if="checkpermission('supplier:view')" label="供应商主数据管理">
                    <supplier departid="103"/>
                </TabPane>
            </Tabs>
        </div>
    </div>

</template>
<script>
    import UserInfoTitleBar from '@/views/common/UserInfoTitleBar.vue'
    import CertificateTemplate from '@/views/admin/CertificateTemplate'
    import Supplier from '@/views/business/workshop/technology/Supplier'
    import MaterialType from '@/views/business/workshop/technology/MaterialType'
    import MaterialInfo from '@/views/business/workshop/technology/MaterialInfo'
    import Bom from '@/views/business/workshop/technology/Bom'
    import MasterModel from '@/views/business/workshop/technology/MasterModel'
    //import CertificateEdit from '@/views/admin/CertificateEdit'

    export default {
        name:'TechDesktop',
        components:{
            'certificatetemplate': CertificateTemplate,
            'loginfobar':UserInfoTitleBar,
            'supplier':Supplier,
            'materialtype':MaterialType,
            'materialinfo':MaterialInfo,
            'bom':Bom,
            'mastermodel':MasterModel
        },
        computed:{
            isV2() {
                let path = this.$route.path
                if(path.indexOf("/business/workhome")!=-1){
                    return true
                }
                return false
            }
        },    
        methods:{
        }    
    }
</script>
