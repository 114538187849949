<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="materialtypeForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
              <i-col span="24">
                <FormItem prop="materialtype" label="物料分类名称:">
                    <i-input type="text" v-model="formInline.materialtype" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('materialtypeForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'materialtypeEdit',
        props:['materialtypedata','show'],
        data () {
            return {
                title:'',
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                    modifyusername:this._self.$root.$store.state.LogonInfo.fullname,
                    materialtype: this.materialtypedata==null?'':this.materialtypedata.materialtype,
                },
                ruleInline: {
                    materialtype: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    materialtypeaddr: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.materialtypedata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.materialtypedata ==null){
                            url = "/api/material/type/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/material/type/update"
                            postdata = this.extend({},this.formInline,{id:this.materialtypedata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SaveOver','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.materialtype= this.materialtypedata==null?'':this.materialtypedata.materialtype
                    
                    if(this.materialtypedata ==null){
                        this.title ='新增物料分类'
                    }
                    else{
                        this.title ='修改物料分类'
                    }
                }
            },
            oncancel(){
                this.$emit('Closematerialtype','')
            },

        }
    }
</script>