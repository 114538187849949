<template>
<div >
        <bomedit v-if="showeditbom" :bomdata="editbomdata" :action="editaction" @closeedit="onEditClose"/>
        <bomview v-if="viewbom" :bomdata="viewbomdata"  @closeview="viewbom=false"/>
        
        <!-- 合格证列表-->
        <div style="padding-top:10px;;" v-if="!showeditbom && !viewbom">
            <Button type="primary" size="large" @click="add"  style="float: left;margin:10px">+增加BOM</Button>
            <Table :columns="tblcolumns" :data="tbldata" border stripe style="clear: both">
            <template slot-scope="{ row }" slot="cnmodelno">
                <strong>{{ row.cnmodelno }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div class="opbar" v-if="deptid=='103'">
                    <Button type="info" size="small"  @click="modify(index)">
                        <Icon type="ios-checkmark" size="20" />
                        修改
                    </Button>
                    <Button type="success" size="small"  @click="view(index)" >查看</Button>
                    <Button type="warning" size="small"  @click="exportbom(index)" >导出</Button>
                </div>
                <div class="opbar" v-else>
                    <Button type="success" size="small"  @click="view(index)" >查看</Button>
                    <Button type="warning" size="small"  @click="exportbom(index)" >导出</Button>
                </div>
            </template>
            </Table>
        </div>
    </div>
</template>

<script>
import BomEdit from '@/views/business/workshop/technology/BomEdit'
import BomView from '@/views/business/workshop/technology/BomView'
export default {
    name:'bom',
    data () {
            return {
                showeditbom:false,
                editbomdata:null,
                viewbom:false,
                viewbomdata:null,
                editaction:null,
                deptid: this._self.$root.$store.state.LogonInfo.departmentid,
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '产品中文型号',
                        slot: 'cnmodelno',
                    },  
                    {
                        title: '产品型号',
                        key: 'modelno',
                    },              
                    {
                        title: 'BOM操作',
                        slot: 'action',
                        align: 'center',
                        fixed: 'right'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            onEditClose(){
                this.showeditbom=false
                this.handleQuery()
            },
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/bom/list",
                    data:{companyid:this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data.data)
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            add(){
                this.editaction = 'add'
                this.showeditbom = true                
            },
            modify (index) {
                this.editbomdata = this.tbldata[index]
                this.editaction = 'modify'
                this.showeditbom = true
            },
            view (index) {
                this.viewbomdata = this.tbldata[index]
                this.viewbom = true
            },
            exportbom (index) {
                let postdata={
                    companyid: this.tbldata[index].companyid,
                    modelno: this.tbldata[index].modelno
                }
                this.$axios({
                    method:'post',
                    url:"/api/bom/export",
                    data:postdata,
                    responseType: 'blob',
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    const link = document.createElement('a')
                    let blob = new Blob([res.data],{type: 'application/vnd.ms-excel'});
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob);
                    let num = ''
                    for(let i=0;i < 10;i++){
                        num += Math.ceil(Math.random() * 10)
                    }

                    link.setAttribute('download', 'BOM-'+ this.tbldata[index].modelno+'.xlsx')
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }.bind(this));
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/bom/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            onEditClose(){
                this.showeditbom =false
            },
            onSubmmitOver(){
                this.showeditbom =false
                this.handleQuery()
            },
        },
        components:{
            bomedit:BomEdit,
            bomview:BomView
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }
</style>