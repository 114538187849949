<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="materialinfoForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
                <i-col span="24">
                    <FormItem prop="mcode" label="物料编码:">
                        <i-input type="text" v-model="formInline.mcode" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
            </Row>
            <Row  >
              <i-col span="24">
                <FormItem prop="materialname" label="物料名称:">
                    <i-input type="text" v-model="formInline.materialname" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="spec" label="物料规格:">
                        <i-input type="text" v-model="formInline.spec" :maxlength=100 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="unit" label="物料单位:">
                        <i-input type="text" v-model="formInline.unit" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="materialtypeId" label="物料分类:">
                        <Select v-model="formInline.materialtypeId" label-in-value	@on-change="onselecttype">
                          <Option v-for="(item,index) in materialtypelist" :value="item.id" :key="item.index">{{item.materialtype}}</Option>
                        </Select>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="supplierid" label="供货单位:">
                        <Select v-model="formInline.supplierid" label-in-value	@on-change="onselectsupplier">
                            <Option v-for="(item,index) in suppliers" :value="item.id" :key="item.index">{{item.suppliername}}</Option>
                        </Select>
                    </FormItem>                         
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="outsourcing" label="需要委外加工:">
                        <Select v-model="formInline.outsourcing" >
                            <Option  value="0" key="0">不需要</Option>
                            <Option  value="1" key="1">需要</Option>
                        </Select>
                    </FormItem>                         
                </i-col>
            </Row>
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('materialinfoForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    const validatenumber = (rule, value, callback) => {
        if (value == '' || value==null) {
          return callback(new Error('不能为空'))
        }
        else{
          return callback()
        }
    }
    export default {
        name:'MaterialInfoEdit',
        props:['materialinfodata','show'],
        data () {
            return {
                title:'',
                suppliers:this.$store.state.MasterSuppliers,
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    modifyuser:this._self.$root.$store.state.LogonInfo.userid,
                    mcode: this.materialinfodata==null?'':this.materialinfodata.mcode,
                    materialname: this.materialinfodata==null?'':this.materialinfodata.materialname,
                    unit: this.materialinfodata==null?'':this.materialinfodata.unit,
                    spec: this.materialinfodata==null?'':this.materialinfodata.spec,
                    materialtypeId: this.materialinfodata==null?'':this.materialinfodata.materialtypeId,
                    materialtype:this.materialinfodata==null?'':this.materialinfodata.materialtype,
                    supplierid:this.materialinfodata==null?'':this.materialinfodata.supplierid,
                    outsourcing:this.materialinfodata==null?'':this.materialinfodata.outsourcing
                },
                ruleInline: {
                    mcode: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    materialname: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    spec: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    materialtypeId: [
                        { required: true, validator: validatenumber, trigger: 'blur'}
                    ],
                    supplierid: [
                        { required: true, validator: validatenumber, trigger: 'blur'}
                    ],
                    outsourcing: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                },
                materialtypelist:[]
            }
        },
        mounted(){
            this.materialtypelist = this._self.$root.$store.state.MaterialType
            console.log(this.materialinfodata)
        },
        methods: {
            handleSubmit (name) {
                console.log(this.formInline)
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.materialinfodata ==null){
                            url = "/api/material/info/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/material/info/update"
                            postdata = this.extend({},this.formInline,{id:this.materialinfodata.id})
                        }

                        postdata = this.extend({},postdata,{supplier:this.getsuppliernamebyid(postdata.supplierid)})
                        postdata = this.removeEmptyChilds(postdata)
                        axios.post(url,postdata)
                        .then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SaveOver','')
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onselecttype(e){
                console.log(e)
                if(e !=null){
                    this.formInline.materialtype = e.label
                }
            },
            onselectsupplier(e){
                console.log(e)
            },
            getsuppliernamebyid(id){
                for(let i=0;i< this.suppliers.length;i++){
                    if(this.suppliers[i].id == id){
                        return this.suppliers[i].suppliername
                    }
                }
                return null;
            },
            onshow(v){
                this.suppliers= this.$store.state.MasterSuppliers

                if(v){
                    this.materialtypelist = this._self.$root.$store.state.MaterialType
                    console.log(this.materialtypelist)
                    this.formInline.mcode= this.materialinfodata==null?'':this.materialinfodata.mcode
                    this.formInline.materialname= this.materialinfodata==null?'':this.materialinfodata.materialname
                    this.formInline.spec= this.materialinfodata==null?'':this.materialinfodata.spec
                    this.formInline.unit= this.materialinfodata==null?'':this.materialinfodata.unit
                    this.formInline.materialtypeId = this.materialinfodata==null?'':this.materialinfodata.materialtypeId
                    this.formInline.materialtype = this.materialinfodata==null?'':this.materialinfodata.materialtype
                    this.formInline.supplier = this.materialinfodata==null?'':this.materialinfodata.supplier
                    this.formInline.supplierid = this.materialinfodata==null?'':this.materialinfodata.supplierid
                    this.formInline.outsourcing = this.materialinfodata==null?'0':this.materialinfodata.outsourcing
                    if(this.materialinfodata ==null){
                        this.title ='新增物料信息'
                    }
                    else{
                        this.title ='修改物料信息'
                    }
                }
            },
            oncancel(){
                this.$emit('Closematerialinfo','')
            },

        }
    }
</script>