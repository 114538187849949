<template>
    <div >
        <Form ref="materialQueryForm" :model="formInline" label-position="right" :label-width="100" style="border:1px solid lightgray;background:lightblue;">
            <Row type="flex" justify="center" align="middle" >
                <i-col span="5" >
                    <FormItem prop="mcode" label="物料编码:" style="margin-top: 20px;">
                        <i-input type="text" v-model="formInline.mcode" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
                <i-col span="5">
                    <FormItem prop="materialName" label="物料名称:" style="margin-top: 20px;">
                        <i-input type="text" v-model="formInline.materialName" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
                <i-col span="5">
                    <FormItem prop="supplier" label="供应商:" style="margin-top: 20px;">
                        <i-input type="text" v-model="formInline.supplier" :maxlength=30 show-word-limit></i-input>
                    </FormItem>
                </i-col>
                <i-col span="5">
                    <div>
                        <Button type="primary" @click="handleQuery('materialQueryForm')" >查询</Button>    
                        <Button  @click="handleReset('materialQueryForm')" style="margin-left:10px">重置</Button>
                    </div>
                </i-col>
                <i-col span="4"></i-col>
            </Row>
        </Form>    
        <div style="height:40px;width:100%;">
            <label style="margin-top:14px;float:left;margin-left:10px;font-weight:bold">物料列表</label>  
            <Button v-hasPermission="'material:manage'" type="primary"  style="margin-top:10px;float:left;margin-left: 30px;" @click="addmaterialinfo">添加物料数据</Button>
            <Page :total="tblpageinfo.total" :page-size="tblpageinfo.pagesize" show-total style="margin-top:6px;float:right"  @on-change="onpagechange"/>
        </div>   
        <!-- 物料列表-->
        <div style="padding-top:10px;clear: both;">
            <Table :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="materialinfoname">
                <strong>{{ row.materialname }}</strong>
            </template>
            <template slot-scope="{ row }" slot="mcode">
                <strong>{{ row.mcode==null?'-':row.mcode }}</strong>
            </template>
            <template slot-scope="{ row }" slot="outsourcing">
                <strong>{{ row.outsourcing==1?'需要':'不需要' }}</strong>
            </template>
            
            <template slot-scope="{ row, index }" slot="action" >
                <Button v-hasPermission="'material:manage'" type="error" size="small" @click="remove(index)" style="margin-right: 5px">删除</Button>
                <Button v-hasPermission="'material:manage'" type="info" size="small" ghost @click="modify(index)">修改</Button>
            </template>
            </Table>
        </div>
        <materialinfoEdit v-bind:show="showeditmaterialinfo" v-bind:materialinfodata="editmaterialinfodata" @Closematerialinfo="onEditClose" @SaveOver="onSubmmitOver"/>
        <Modal v-model="showdeleteconfirm" title="删除物料信息" @on-ok="confirmDelete">确定要删除吗？</Modal>
    </div>
</template>

<script>        
import MaterialInfoEdit  from '@/views/business/workshop/technology/MaterialInfoEdit '
export default {
    name:'MaterialInfo',
    data () {
            return {
                showeditmaterialinfo:false,
                editmaterialinfodata:null,
                showdeleteconfirm:false,
                deleteIndex:null,
                formInline:{
                    materialName:null,
                    mcode:null,
                    supplier:null,
                    pageSize:20,
                    pageNum:0
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '物料编码',
                        slot: 'mcode',
                    },
                    {
                        title: '物料名称',
                        slot: 'materialinfoname',
                    },
                    {
                        title: '物料规格',
                        key: 'spec',
                    },   
                    {
                        title: '物料单位',
                        key: 'unit',
                    },           
                    {
                        title: '物料分类',
                        key: 'materialtype',
                    },    
                    {
                        title: '供应商',
                        key: 'supplier',
                    }, 
                    {
                        title: '委外加工',
                        slot: 'outsourcing',
                    }, 
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center'
                    }
                    
                ],
                tblpageinfo:{
                    total:0,
                    currentpage:1,
                    pagesize:7
                },
                tbldata: []
            }
        },
        mounted (){
            
            this.handleQuery()
        },
        methods: {
            handleReset (name) {
                this.$refs[name].resetFields();
                this.handleQuery()
            },
            onpagechange(v){
                this.formInline.pageNum = v
                this.handleQuery()
            },
            handleQuery() {
                let postdata = this.extend({},this.formInline,{companyid:this._self.$root.$store.state.LogonInfo.companyid})
                postdata = this.removeEmptyChilds(postdata)
                axios.post("/api/material/info/query",postdata)
                .then(function(res){
                    console.log(res)
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data.data
                        console.log(res.data.data)
                        if(rspdata == null){
                            this.tbldata=[]
                        }
                        else{
                            this.tblpageinfo.total = res.data.data.totalnumber
                            this.tbldata =rspdata
                        }
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addmaterialinfo(){
                this.editmaterialinfodata = null
                this.showeditmaterialinfo = true                
            },
            modify (index) {
                this.editmaterialinfodata = this.tbldata[index]
                this.showeditmaterialinfo = true
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/material/info/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                        eventBus.$emit('materialinfoupdate',{operation:'delete'})
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditmaterialinfo =false
            },
            onSubmmitOver(){
                this.showeditmaterialinfo =false
                this.handleQuery()
                eventBus.$emit('materialinfoupdate',{operation:'update'})
            },
        },
        components:{
            materialinfoEdit:MaterialInfoEdit
        }
}
</script>